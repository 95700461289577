<template>
  <e-modal
    :visible="visible"
    title="菜品查询"
    :width="800"
    :height="600"
    :loading="loading"
    @close="onClose"
  >
    <template #footer>
      <div class="bottoms">
        <div class="pagination on">
          <a-pagination
            :total="page.total"
            size="small"
            simple
            v-model:current="page.page"
            v-model:page-size="queryParams.Limit"
            :hide-on-single-page="true"
            @change="changePage"
          >
            <template #itemRender="{ page, type }">
              <a v-if="type === 'prev'" class="ant-pagination-item-link">
                上一页
              </a>
              <a v-else-if="type === 'next'" class="ant-pagination-item-link">
                下一页
              </a>
              <span v-else>{{ page }}</span>
            </template>
          </a-pagination>
        </div>

        <div class="actions">
          <a-row>
            <a-col>
              <a-input v-model:value="queryParams.Name" />
            </a-col>
            <a-col>
              <a-button @click="getDataList(1)">搜索</a-button>
            </a-col>
            <a-col>
              <a-button style="margin-left: 15px" @click="onChooseAll">
                全选
              </a-button>
              <a-button style="margin-left: 5px" @click="onChooseBack">
                反选
              </a-button>

              <a-button
                style="margin-left: 5px"
                type="primary"
                :disabled="selects.length === 0"
                @click="onChoose"
              >
                选择<span v-if="selects.length > 0">({{ selects.length }})</span>
              </a-button>
            </a-col>
          </a-row>
        </div>
      </div>
    </template>
    <a-spin tip="Loading..." :spinning="loading">
      <a-row :gutter="[5, 5]">
        <a-col :span="6" v-for="(v, k) in list" :key="k">
          <div
            :class="{
              border: true,
              on: checkIn(v.id),
            }"
            @click="onChooseOne(k)"
          >
            <a-card hoverable>
              <template #cover>
                <div class="image">
                  <img alt="example" :src="$filters.image(v.pic)" />
                </div>
              </template>
              <a-card-meta :description="'售价：¥' + v.sellingPrice">
                <template #title>
                  <div class="title">
                    <div class="zhangweifu">占位符</div>
                    <div class="dish-title">{{ v.name }}</div>
                  </div>
                </template>
              </a-card-meta>
            </a-card>
          </div>
        </a-col>
      </a-row>
    </a-spin>
  </e-modal>
</template>

<script>
import { defineComponent, ref, toRaw } from 'vue'
import { DishClass } from '@/apis/dish'
import page from '@/mixins/page'

const api = new DishClass()
export default defineComponent({
  mixins: [page],
  emits: ['ok'],
  setup (props, { emit }) {
    const loading = ref(false)
    const visible = ref(false)
    const selects = ref([])

    const onClose = () => {
      loading.value = false
      visible.value = false
    }

    const onChoose = () => {
      emit('ok', toRaw(selects.value))
      onClose()
    }

    return {
      visible,
      loading,
      selects,
      onClose,
      onChoose
    }
  },

  methods: {
    open () {
      this.selects = []
      this.getDataList(1)
      this.visible = true
    },
    checkIn (id) {
      return this.selects.includes(id)
    },
    getDataList (page) {
      this.loading = true
      this.page.page = page
      const q = { ...this.queryParams }
      q.Offset = (page - 1) * q.Limit
      q.Type = 0
      api.gets(q).then((resp) => {
        this.list = resp.data
        this.page.total = resp.total
        this.loading = false
      })
    },
    onChooseOne (index) {
      const row = this.list[index]
      const s = new Set(this.selects)
      if (s.has(row.id)) {
        s.delete(row.id)
      } else {
        s.add(row.id)
      }
      this.selects = Array.from(s)
    },
    onChooseBack () {
      this.list.map((v, k) => {
        this.onChooseOne(k)
      })
    },
    onChooseAll () {
      const s = new Set(this.selects)
      this.list.map(v => {
        s.add(v.id)
      })
      this.selects = Array.from(s)
    }
  }
})
</script>

<style lang="less" scoped>
.image {
  width: 100%;
  height: 150px;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
  }
}
.bottoms {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.border {
  border: 1px solid transparent;
  &.on {
    border-color: #000;
  }
}
.title {
  position: relative;

  .zhangweifu {
    visibility: hidden;
    height: 40px;
  }
  .dish-title {
    position: absolute;
    top: 0;
    left: 0;
    height: 40px;
    display: flex;
    align-items: center;
    white-space: pre-wrap;
    line-height: 20px;
  }
}
</style>
