<template>
  <div>
    <a-select
      v-model:value="value"
      label-in-value
      showSearch
      placeholder="搜索菜品"
      style="width: 100%"
      :filter-option="false"
      :not-found-content="fetching ? undefined : null"
      :options="data"
      @search="fetchDatas"
      @change="onChoose"
    >
      <template #suffixIcon>
        <LoadingOutlined v-if="fetching" />
      </template>
      <template v-if="fetching" #notFoundContent>
        <a-spin size="small" />
      </template>
    </a-select>
  </div>
</template>

<script>
import { defineComponent, reactive, watch, toRefs, ref } from 'vue'
import { LoadingOutlined } from '@ant-design/icons-vue'
import { debounce } from 'lodash-es'
import { DishClass } from '@/apis/dish'

const api = new DishClass()
export default defineComponent({
  components: {
    LoadingOutlined
  },
  props: {
    modelValue: [String, Number],
    title: {
      type: String,
      default: '选择菜品'
    }
  },
  emits: ['ok', 'update:modelValue'],
  setup (props, { emit }) {
    let lastFetchId = 0
    const state = reactive({
      data: [],
      value: [],
      fetching: false
    })

    const list = ref([])
    const fetchDatas = debounce(value => {
      if (!value) return
      console.log('fetching', value)
      lastFetchId += 1
      const fetchId = lastFetchId
      state.data = []
      state.fetching = true

      const q = {
        Name: value,
        Limit: 10
      }

      api.gets(q).then(resp => {
        if (fetchId !== lastFetchId) {
          return
        }
        list.value = resp.data
        const data = resp.data.map(resp => ({
          label: resp.name,
          value: resp.id
        }))
        state.data = data
        state.fetching = false
      })
    }, 300)

    watch(state.value, e => {
      state.data = []
      state.fetching = false
    })

    const onChoose = e => {
      const item = list.value.filter(x => x.id === e.value)[0]

      emit('ok', item)
      emit('update:modelValue', e.value)
    }

    return {
      ...toRefs(state),
      fetchDatas,
      onChoose
    }
  },

  created () {
    this.$watch('modelValue', e => {
      if (!e) {
        this.reset()
      } else {
        this.value = {
          key: e,
          value: e
        }
      }
    })
  },
  methods: {
    reset () {
      this.value = null
      this.data = []
      this.fetching = false
    }
  }
})
</script>
